$bgColorMain: transparent;
$bgColorContainer: transparent;
$fontColorContainer: #f2f2f2;
$paddingContainer: 2px;
$paddingNone: 0;
$marginNone: 0;

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#ds-d2 #banquetPortalUl {
    background: transparent;
}

@media (orientation: landscape ){

  #ds-d2 {
    &.container {
        margin: $marginNone;
        padding: 4vh 2vw;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .entry {
      margin: $marginNone;
      background: #f2f2f2;
      color: #000000;
      padding: 2%;
      height: 72vh;
      width: 96vw;
    }
    .eventArea {
      color: $fontColorContainer;
      padding: $paddingNone;
    }
    .component-logo {
      line-height: 1.4;
      height: 17vh;
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 20vh;
      width: 96vw;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      color: #f2f2f2;
      text-align: right;
      font-size: 3vh;
      line-height: 1.4;
    }
    .center {
      text-align: center;
    }
    .eventBrand {
      height: 30vh;
      width: 60vw;
      display: inline-block;
    }
    img.event-logo {
      padding: 0 0 1vh 0;
      max-height: 100%;
      max-width: 100%;
    }
    .roomNameContainer {
      width: 100%;
      text-align: center;
    }
    .roomName {
      font-size: 4vh;
      line-height: 1.5;
      height: 8vh;
      display: inline-block;
      max-width: 65%;
    }
    .roomPic {
      height: 4vh;
      width: 2vw;
      position: relative;
      bottom: -10px;
    }
    svg {
      margin-right: 1vw;
      float: left;
    }
    .roomTXT {
      float: left;
    }
    .roomTIME {
      float: left;
    }
    .roomTXT {
      max-width: 65%;
      padding: 0 1vw 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomTIME::before {
      content: "|";
      padding: 0 1vw;
    }
    .roomTIME::after {
      clear: both;
    }
    .center .title {
      font-family: 'open_sansregular';
      font-size: 8vh;
      line-height: 1.5;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 1;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .center .description {
      font-family: 'open_sanslight';
      font-size: 5vh;
      line-height: 1.2em;
      display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (orientation: portrait ){

  #ds-d2 {
    &.container {
        margin: $marginNone;
        padding: 3vh 5vw;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .entry {
      margin: 0;
      background: #f2f2f2;
      color: #000000;
      padding: 2%;
      height: 100%;
      width: 100%;
    }
    .eventArea {
      position: absolute;
      color: #f2f2f2;
      padding: 0;
      height: 82vh;
      width: 90vw;
      top: 15vh;
    }
    .component-logo {
      height: 10vh;
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 10vh;
      width: 90vw;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      color: #f2f2f2;
      text-align: right;
      font-size: 2vh;
      line-height: 1.4;
    }
    .center {
      text-align: center;
    }
    .eventBrand {
      height: 28vh;
      width: 80vw;
      display: inline-block;
      padding: 5%;
    }
    img.event-logo {
      max-height: 100%;
      max-width: 100%;
    }
    .roomName {
      font-size: 2vh;
      padding: 0 0 1.5vh 0;
      line-height: 1.5;
    }
    .roomPic {
      height: 2vh;
      width: 3vw;
      position: relative;
      bottom: 0;
    }
    svg {
      margin-right: 0.5vw;
    }
    .roomTXT,
    .roomTIME {
    }
    .roomTXT {
      max-width: 65%;
      padding: 0 1vw 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomTIME::before {
      content: "|";
      padding: 0 1vw;
    }
    .roomTIME::after {
      clear: both;
    }
    .center .title {
      font-family: 'open_sansregular';
      font-size: 6vh;
      line-height: 1.2;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .center .description {
      font-family: 'open_sanslight';
      font-size: 4vh;
      line-height: 1.2em;
      display: -webkit-box;
        -webkit-line-clamp: 4;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
