$bgColorMain: transparent;
$bgColorContainer: transparent;
$fontColorContainer: #f2f2f2;
$paddingContainer: 2px;
$paddingNone: 0;
$marginNone: 0;

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#ds-d3 #banquetPortalUl {
    background: transparent;
}

@media (orientation: landscape ){

  #ds-d3 {
    &.container {
        margin: $marginNone;
        padding: 4vh 0;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .entry {
      margin: $marginNone;
      background: #f2f2f2;
      color: #000000;
      padding: 4vh 2vw;
      height: 72vh;
      width: 100vw;
      clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 88%);
    }
    .eventArea {
      color: $fontColorContainer;
      padding: $paddingNone;
    }
    .component-logo {
      line-height: 1.4;
      height: 15vh;
      text-align: right;
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 15vh;
      width: 96vw;
      margin: 0 2vw;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      color: #f2f2f2;
      text-align: left;
      font-size: 3vh;
      line-height: 1.4;
      padding: 0 2vw;
    }
    .center {
      text-align: left;
    }
    .eventBrand {
      height: 18vh;
      width: 30vw;
      display: inline-block;
      margin: 6vh 0 4vh 0;
    }
    img.event-logo {
      padding: 0 0 1vh 0;
      max-height: 100%;
      max-width: 100%;
    }
    .roomName {
      font-size: 4vh;
      padding: 0 0 1.5vh 0;
      line-height: 1.5;
      height: 8vh;
    }
    .roomPic {
      height: 4vh;
      width: 2.5vw;
      position: relative;
      bottom: -6px;
    }
    svg {
      margin-right: 1vw;
      float: left;
    }
    .roomTXT {
      float: left;
    }
    .roomTIME {
      float: left;
    }
    .roomTXT {
      max-width: 75%;
      padding: 0 1vw 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomTIME::before {
      content: "|";
      padding: 0 1vw;
    }
    .roomTIME::after {
      clear: both;
    }
    .center .title {
      font-family: 'open_sansregular';
      font-size: 8vh;
      line-height: 1.5;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 1;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .center .description {
      font-family: 'open_sanslight';
      font-size: 5vh;
      line-height: 1.4;
      display: -webkit-box;
        -webkit-line-clamp: 1;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (orientation: portrait ){

  #ds-d3 {
    &.container {
        margin: $marginNone;
        padding: 4vh 0;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .entry {
      margin: $marginNone;
      background: #f2f2f2;
      color: #000000;
      padding: 4vh 2vw;
      height: 78vh;
      width: 100vw;
      clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 88%);
    }
    .eventArea {
      color: $fontColorContainer;
      padding: $paddingNone;
    }
    .component-logo {
      line-height: 1.4;
      height: 10vh;
      text-align: right;
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 10vh;
      width: 96vw;
      margin: 0 2vw;
    }
    .header .trx-2-3 {
      width: 25%;
    }
    .header .trx-1-3 {
      width: 75%;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      color: #f2f2f2;
      text-align: left;
      font-size: 3vh;
      line-height: 1.4;
      padding: 0 2vw;
    }
    .center {
      text-align: left;
    }
    .eventBrand {
      height: 20vh;
      width: 50vw;
      display: inline-block;
      margin: 5vh 0 2vh 0;
    }
    img.event-logo {
      padding: 0 0 1vh 0;
      max-height: 100%;
      max-width: 100%;
    }
    .roomName {
      font-size: 2vh;
      padding: 0 0 1.5vh 0;
      line-height: 1.5;
      height: 5vh;
    }
    .roomPic {
      height: 2vh;
      width: 2.5vw;
      position: relative;
      bottom: -6px;
    }
    svg {
      margin-right: 2vw;
      float: left;
    }
    .roomTXT {
      float: left;
    }
    .roomTIME {
      float: left;
    }
    .roomTXT {
      max-width: 65%;
      padding: 0 1vw 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomTIME::before {
      content: "|";
      padding: 0 1vw;
    }
    .roomTIME::after {
      clear: both;
    }
    .center .title {
      font-family: 'open_sansregular';
      font-size: 3.5vh;
      line-height: 1.5;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .center .description {
      font-family: 'open_sanslight';
      font-size: 3vh;
      line-height: 1.4;
      display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .footer .trx-2-3 {
      width: 95%;
    }
    .footer .trx-1-3 {
      width: 5%;
    }
  }
}
