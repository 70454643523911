/*!
trx.sass v0.0.1
Author: caza // TRIAX GmbH Deutschland
*/

$font-height: 1.5;

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  line-height: $font-height;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}

.trx {
  text-rendering: optimizespeed;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
}

/***** grid *****/

.trx-1,
.trx-5-5,
.trx-4-4 {
  width: 100%;
}
.trx-9-10_5 {
  width: 89.5%;
}
.trx-11-12 {
  width: 88%;
  display: inline-block;
}
.trx-7-8 {
  width: 87.5%;
}
.trx-5-6 {
  width: 83.33333%;
}
.trx-4-5,
.trx-8-10 {
  width: 80%;
}
.trx-3-4,
.trx-6-8 {
  width: 75%;
}
.trx-7-10 {
  width: 70%;
}
.trx-2-3 {
  width: 66.66667%;
}
.trx-3-5,
.trx-6-10 {
  width: 60%;
}
.trx-7-12 {
  width: 58.33333%;
}
.trx-1-2,
.trx-3-6,
.trx-4-8,
.trx-5-10 {
  width: 50%;
}
.trx-5-12 {
  width: 41.66666%;
}
.trx-2-5,
.trx-4-10 {
  width: 40%;
}
.trx-1-3,
.trx-2-6 {
  width: 33.33333%;
}
.trx-1-4,
.trx-2-8 {
  width: 25%;
}
.trx-1-5,
.trx-2-10 {
  width: 20%;
}
.trx-1-6 {
  width: 16.66667%;
}
.trx-1-7 {
  width: 14.28571%;
}
.trx-1-8 {
  width: 12.5%;
}
.trx-1-9 {
  width: 11.11111%;
}
.trx-1-10 {
  width: 10%;
}
.trx-1-11 {
  width: 9.09091;
}
.trx-1-12 {
  width: 8.33333%;
}
