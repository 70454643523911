$bgColorMain: transparent;
$bgColorContainer: transparent;
$fontColorContainer: #f2f2f2;
$paddingContainer: 2px;
$paddingNone: 0;
$marginNone: 0;

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#ds-d1 #banquetPortalUl {
    background: transparent;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

  #ds-d1 {

    .right .title {
      -webkit-line-clamp: 3 !important;
    }
    .right .description.description {
      -webkit-line-clamp: 2 !important;
    }
    .details {
      & .roomName,
        .eventTime {
          font-size: 3.6vh !important;
        }
    }
    .roomPic {
      height: 3vh !important;
      bottom: -10px !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

  #ds-d1 {

    .right .title.title {
      -webkit-line-clamp: 4 !important;
    }
    .right .description.description {
      -webkit-line-clamp: 5 !important;
    }
  }
}

@media only screen and (max-device-width: 1280px) and (min-device-width: 720px) and (orientation: landscape) {

  #ds-d1 {
    & .roomPic {
      bottom: -12px !important;
    }
  }
}

@media (orientation: landscape ){

  #ds-d1 {
    &.container {
        margin: $marginNone;
        padding: 4vh 2vw;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .entry {
      color: #ffffff;
      margin: $marginNone;
      width: 96vw;
      height: 60vh;
      overflow: hidden;
      padding: 2vh 1vw;

    }
    .eventArea {
      color: $fontColorContainer;
      padding: 0;
    }
    .component-logo {
      line-height: 1.4;
      height: 20vh;
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 20vh;
      width: 96vw;
      margin-bottom: 10vh;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      color: #f2f2f2;
      text-align: right;
      font-size: 3vh;
      line-height: 1.4;
    }
    .left{
      width: 23vw;
      height: 56vh;
      text-align: center;
    }
    img.event-logo {
      max-width: 100%;
      max-height: 100%;
    }
    .right {
      height: 56vh;
      overflow: hidden;
      padding: 0 0 0 2vw;
    }
    .details {
      font-size: 4vh;
      padding: 0 0 2vh 0;
      line-height: 1.5;
    }
    .roomPic {
      height: 4vh;
      width: 2vw;
      position: relative;
      bottom: -18px;
    }
    svg {
      margin-right: 1vw;
      float: left;
    }
    .roomTXT,
    .roomTIME {
      float: left;
    }
    .roomTXT {
      max-width: 65%;
      padding: 0 1vw 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomTIME::before {
      content: "|";
      padding: 0 1vw;
    }
    .roomTIME::after {
      clear: both;
    }
    .details {
      & .roomName,
        .eventTime {
          font-family: 'open_sansregular';
          font-size: 5vh;
          margin: 0;
        }
        .roomName {
          width: 100%;
        }
        .eventTime {
          text-align: right;
        }
    }
    .right .title {
      font-family: 'open_sansregular';
      font-size: 8vh;
      line-height: 1.2em;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .right .description {
      font-family: 'open_sanslight';
      font-size: 5vh;
      line-height: 1.2em;
      display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (orientation: portrait ){

  #ds-d1 {
    &.container {
        margin: $marginNone;
        padding: 4vh 6vw;
        width: 100vw;
        height: 100vh;
        background: none;
    }
    .eventArea {
      color: $fontColorContainer;
      padding: 5vh 0 0 0;
    }
    .entry {
      padding: 2vh 3.5vw;
    }
    .component-logo {
      height: 10vh
    }
    .eventArea .entry:nth-child(2) {
      display: none;
    }
    .header {
      height: 10vh;
      width: 88vw;
    }
    .header img {
      max-width: 100%;
      max-height: 100%;
    }
    .clock {
      position: absolute;
      bottom: 2.5vh;
      right: 6vw;
      color: #f2f2f2;
      text-align: right;
      font-size: 1.5vh;
    }
    .left{
      width: 100%;
      height: 16vh;
      justify-content: center;
      align-items: center;
      padding: 0 1vh 2vh 0;
      img.event-logo {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right{
      width: 100%;
    }
    .right {
      height: 55vh;
      overflow: hidden;
    }
    .details {
      font-size: 4vh;
      padding: 0 0 2vh 0;
      line-height: 1.5;
    }
    .roomPic {
      height: 4vh;
      width: 4vw;
      position: relative;
      bottom: -0.4vh;
    }
    svg {
      margin-right: 2vw;
      float: left;
    }
    .roomTXT::after {
      clear: both;
    }
    .roomTXT {
      max-width: 100%;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .details {
      & .roomName,
        .eventTime {
          font-size: 3.5vh;
          line-height: 1.4;
          margin: 0;
        }
        .roomName {
          width: 100%;
        }
        .eventTime {
          font-family: 'open_sanslight';
          text-align: right;
        }
    }
    .right .title {
      font-family: 'open_sansregular';
      font-size: 3.5vh;
      line-height: 1.2em;
      text-transform: uppercase;
      padding: 2vh 0 0 0;
      display: -webkit-box;
        -webkit-line-clamp: 4;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .right .description {
      font-family: 'open_sanslight';
      font-size: 3vh;
      line-height: 1.2em;
      display: -webkit-box;
        -webkit-line-clamp: 5;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
