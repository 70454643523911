/***** @font-face *****/

@font-face {
  font-family: 'trx-icn';
  src: url('../../../styles/fonts/TRIAX-Regular.woff') format('woff'),
       url('../../../styles/fonts/TRIAX-Regular.woff2') format('woff2'),
       url('../../../styles/fonts/TRIAX-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/***** custom css *****/

.icons {
height: auto;
display: inline-block;
}

.icon {
font-family: 'trx-icn';
line-height: 1;
font-size: 7.5vh;
width: 33.3333%;
float: left;
color:#424242;
}

.trx_icn_0 {
content: "";
}
.trx_icn_01::before {
content: "\E001";
}
.trx_icn_02::before {
content: "\E002";
}
.trx_icn_03::before {
content: "\E003";
}
.trx_icn_04::before {
content: "\E004";
}
.trx_icn_05::before {
content: "\E005";
}
.trx_icn_06::before {
content: "\E006";
}
.trx_icn_07::before {
content: "\E007";
}
.trx_icn_08::before {
content: "\E008";
}
.trx_icn_09::before {
content: "\E009";
}
.trx_icn_10::before {
content: "\E010";
}
.trx_icn_11::before {
content: "\E011";
}
.trx_icn_12::before {
content: "\E012";
}
.trx_icn_13::before {
content: "\E013";
}
.trx_icn_14::before {
content: "\E014";
}
.trx_icn_15::before {
content: "\E015";
}
.trx_icn_16::before {
content: "\E016";
}
.trx_icn_17::before {
content: "\E017";
}
.trx_icn_18::before {
content: "\E018";
}
.trx_icn_19::before {
content: "\E019";
}
.trx_icn_20::before {
content: "\E020";
}
.trx_icn_21::before {
content: "\E021";
}
.trx_icn_22::before {
content: "\E022";
}
.trx_icn_23::before {
content: "\E023";
}
.trx_icn_24::before {
content: "\E024";
}
.trx_icn_25::before {
content: "\E025";
}
.trx_icn_26::before {
content: "\E026";
}
.trx_icn_27::before {
content: "\E027";
}
.trx_icn_28::before {
content: "\E028";
}
.trx_icn_29::before {
content: "\E029";
}
.trx_icn_30::before {
content: "\E030";
}
.trx_icn_31::before {
content: "\E031";
}
.trx_icn_32::before {
content: "\E032";
}
.trx_icn_33::before {
content: "\E033";
}
.trx_icn_34::before {
content: "\E034";
}
.trx_icn_35::before {
  content: "\E035";
}
.trx_icn_36::before {
  content: "\E036";
}
