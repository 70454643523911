@import "./trxsass/trxsass";

/***** @font-face *****/

@font-face {
    font-family: 'open_sansregular';
    src: url('./fonts/OpenSans-Regular-webfont.eot');
    src: url('./fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('./fonts/OpenSans-Light-webfont.eot');
    src: url('./fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** body *****/
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    color: #7B7979;
    background: #fff;
    font-family: 'open_sansregular';
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2em;
    font-size: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    margin: 0;
    padding: 0;
}

/*
// DEBUG WARNING
// red backgroundcolor/white fontcolor and bottom right
// will be only show if DEBUG Mode is on (config.js)
*/
#debugwarning {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #D00;
    color: #eee;
    font-weight: 700;
    padding: 1px;
    z-index: 1000;
}

/*
// DEBUG Clock
// black backgroundcolor/white fontcolor and bottom left
// will be only show if DEBUG_CLOCK Mode is on (config.js)
*/
#debugclock {
    color: #000;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #111;
    color: #eee;
    font-weight: 700;
    padding: 1px;
    z-index: 1000;
}

/*
// App Container
// set the main css parameters for the app container for backgroundimage
//
*/
#App {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
