$fontColor: #222;

@media (orientation: portrait ){

  div#d0 {
    color: #F2F2F2;
    background: transparent!important;
    padding: 2vh 4vw;
    line-height: 1.5
  }

  #d0 {

    .trx.header {
      height: 20vh;
      overflow: hidden;
    }

    .trx-1-3.left {
      height: 100%;
    }

    .left img {
      max-width: 100%;
      max-height: 100%;
    }

    .right {
      font-size: 2vh;
      color: #F2F2F2;
      text-align: right;
    }

    .roomSetup {
      width: 100%;
      float: right;
    }

    .roomName {
      font-size: 3vh;
      padding: 0 0 1.5vh 0;
      line-height: 1.5;
      float: right;
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-right: 1vw;
      height: 3vh;
      width: 4vw;
      position: relative;
      bottom: -4px;
    }
  }
}

@media (orientation: landscape ){

  div#d0 {
    color: #F2F2F2;
    background: transparent!important;
    padding: 7.5vh 4vw;
    line-height: 1.5
  }

  #d0 {

    .trx.header {
      height: 20vh;
      overflow: hidden;
    }

    .trx-1-3.left {
      height: 100%;
    }

    .left img {
      max-width: 100%;
      max-height: 100%;
    }

    .right {
      font-size: 3vh;
      color: #F2F2F2;
      text-align: right;
    }

    .roomSetup {
      width: 100%;
      height: 100%;
      float: right;
    }

    .roomName {
      font-size: 4vh;
      padding: 0 0 1.5vh 0;
      line-height: 1.5;
      float: right;
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-right: 1vw;
      height: 4vh;
      width: 2vw;
      position: relative;
      bottom: -5px;
    }
  }
}
